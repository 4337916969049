// eslint-disable-next-line
document.addEventListener('DOMContentLoaded', function () {
    /* ======================================= *
     *    Add character count to textareas     *
     * ======================================= */
    const textareas = document.getElementsByClassName('textarea-charcount');
    for (let i = 0; i < textareas.length; i += 1) {
        const newElement = document.createElement('div');
        let { maxLength } = textareas[i].dataset;

        newElement.innerText = maxLength ? `${textareas[i].value.length} / ${maxLength} tekens` : `${textareas[i].value.length} tekens`;
        newElement.setAttribute('class', 'textarea-charcount-container');
        textareas[i].parentNode.insertBefore(newElement, textareas[i].nextSibling);

        // eslint-disable-next-line
        textareas[i].addEventListener('input', function (e) {
            // eslint-disable-next-line prefer-destructuring
            maxLength = e.currentTarget.dataset.maxLength;

            if (maxLength && textareas[i].value.length > maxLength) {
                e.currentTarget.nextSibling.classList.add('too-much-chars');
            } else {
                e.currentTarget.nextSibling.classList.remove('too-much-chars');
            }

            e.currentTarget.nextSibling.innerText = maxLength ? `${textareas[i].value.length} / ${maxLength} tekens` : `${textareas[i].value.length} tekens`;
        });
    }
});
