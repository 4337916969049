/* eslint-disable */
$('#gmaps-script').ready(function(){
    const categoriesSelect = document.getElementsByClassName('category-option');
    const companyList = document.getElementById('companies-list')
    const companySelect = document.getElementsByClassName('list-company');
    const companyMap = document.getElementById('companyMap');
    const companyPopup = document.getElementById('companyPopup');
    let popup = undefined;

    // Default coordinates to center the map
    const flandersCoordinates = { lat: 50.996464364869006, lng: 4.274583251659164 };

    if (!companyMap) {
        return;
    }

    const map = new google.maps.Map(companyMap);

    focus(flandersCoordinates.lat, flandersCoordinates.lng, false,8);

    let markers = [];
    let selectedFilters = [];
    updateMarkers();

    /**
     * A customized popup on the map.
     */
    class Popup extends google.maps.OverlayView {
        constructor(position, content) {
            super();
            this.position = position;
            content.classList.add('popup-bubble');

            // This zero-height div is positioned at the bottom of the bubble.
            const bubbleAnchor = document.createElement('div');
            bubbleAnchor.classList.add('popup-bubble-anchor');
            bubbleAnchor.appendChild(content);

            // This zero-height div is positioned at the bottom of the tip.
            this.containerDiv = document.createElement('div');
            this.containerDiv.classList.add('popup-container');
            this.containerDiv.appendChild(bubbleAnchor);

            // Optionally stop clicks, etc., from bubbling up to the map.
            Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
        }

        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
            if (this.containerDiv.parentElement) {
                this.containerDiv.parentElement.removeChild(this.containerDiv);
            }
        }

        /** Called each frame when the popup needs to draw itself. */
        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

            // Hide the popup when it is far out of view.
            const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

            if (display === 'block') {
                this.containerDiv.style.left = divPosition.x + 'px';
                this.containerDiv.style.top = divPosition.y + 'px';
            }

            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
        }
    }

    for (let i = 0; i < companySelect.length; i++) {
        companySelect[i].addEventListener('click', function () {
            onCompanyClick(i);
        });
    }

    for (let i = 0; i < categoriesSelect.length; i++) {
        categoriesSelect[i].addEventListener('change', function(e) {
            let elements = Array.prototype.slice.call(categoriesSelect, 0);

            let checkedBoxes = elements.filter((checkbox) => {
                return checkbox.checked;
            });

            selectedFilters = checkedBoxes.map((checkbox) => {
                return checkbox.id;
            })

            updateCompanies();
            updateMarkers();
        });
    }

    function updateCompanies() {
        for(let i = 0; i < companyList.children.length; i++) {
            let style = "display:none"
            if (selectedFilters.length !== 0) {
                const classes = companyList.children[i].className;
                selectedFilters.forEach(function(sel) {
                    if (classes.includes('category-' + sel)) {
                        style = "";
                    }
                });
            }
            companyList.children[i].style = style;
        }
    }

    function setMarker(lat, lng, title = '', companyIndex = -1) {
        lat = parseFloat(lat);
        lng = parseFloat(lng);

        let marker = new google.maps.Marker({
            position: { lat, lng },
            map,
            title
        });

        if (companyIndex !== -1) {
            marker.addListener('click', function () {
                onCompanyClick(companyIndex)
            });
        }

        markers.push(marker);
    }

    function focus(lat, lng, showPopup = false, zoom = 12) {
        lat = parseFloat(lat);
        lng = parseFloat(lng);

        map.setCenter({ lat, lng });
        map.setZoom(zoom);

        if (true === showPopup) {
            popup = new Popup(
                new google.maps.LatLng(lat, lng),
                document.getElementById('companyPopup'),
            );

            popup.setMap(map);
        }
    }

    function updateMarkers() {
        //remove existing markers
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }

        markers = [];

        let addresses = [];
        let names = [];

        for (let i = 0; i < companyList.children.length; i++) {
            if (companyList.children[i].style.display !== 'none') {
                names.push(companyList.children[i].children[0].children[0].innerHTML);
                addresses.push({
                    lat: companyList.children[i].dataset.lat,
                    lng: companyList.children[i].dataset.lng,
                });
            }
        }

        addresses.forEach(function(item, key) {
            if (item.lat && item.lng) {
                setMarker(item.lat, item.lng, undefined, key);
            }
        });
    }

    function toggleDescription(index) {
        const item = document.getElementById('cyber-company-' + index);
        item.classList.toggle('closed');
    }

    function isOpening(index) {
        const item = document.getElementById('cyber-company-' + index);
        return item.classList.contains('closed');
    }

    function closeAllOtherDescriptions(index) {
        for (let i = 0; i < companySelect.length; i++) {
            if (i !== index) {
                const item = document.getElementById('cyber-company-' + i);
                item.classList.add('closed');
            }
        }
    }

    /**
     * Show the popup container corresponding to the given company
     * @param selectedCompany
     */
    function showPopupContainer(selectedCompany) {
        companyPopup.parentElement.parentElement.classList.remove('hidden');
        companyPopup.innerHTML = selectedCompany.dataset.popupContent;
    }

    /**
     * Hide all popup containers
     */
    function hideAllPopupContainers() {
        const popupContainers = document.getElementsByClassName('popup-container');

        if (typeof popupContainers !== 'undefined') {
            for (let i = 0; i < popupContainers.length; i++) {
                popupContainers[i].classList.add('hidden');
            }
        }
    }

    function onCompanyClick(companyIndex) {
        const opening = isOpening(companyIndex);
        closeAllOtherDescriptions(companyIndex);
        toggleDescription(companyIndex);

        if (opening) {
            // We're opening a company -> put focus on the map & show popup
            focus(companySelect[companyIndex].dataset.lat, companySelect[companyIndex].dataset.lng, true);
            showPopupContainer(companySelect[companyIndex]);
        } else {
            // We're closing a company -> remove focus on the map & hide all popups
            focus(flandersCoordinates.lat, flandersCoordinates.lng, false, 8);
            hideAllPopupContainers();
        }
    }
});
