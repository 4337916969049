require('../css/main.scss');

// Load sliced files
require('../external/js/app');
require('select2');

// Load polyfill for HTML5 History API for older browsers
require('html5-history-api');

// Load components
require('./components/membership_requests');
require('./components/group_conversation');
require('./components/autosubmit');
require('./components/accordion');
require('./components/tabs');
require('./components/print');
require('./components/flash');
require('./components/toggles');
require('./components/avatar_preview');
require('./components/flatpickr_config');
require('./components/block_double_submit');
require('./components/maps_autocomplete');
require('./components/cyber_security_select');
require('./components/form_collection_add_remove');
require('./components/climate_project');
require('./components/textarea');
require('./components/cookie_consent');
require('./components/vacancy');
require('./components/news');
