$(document).ready(() => {
    const action = $('#membership_action');
    const form = $('#membership_requests');

    $('#approve_memberships').click(() => {
        action.val('approve');
        form.submit();
    });

    $('#deny_memberships').click(() => {
        action.val('deny');
        form.submit();
    });

    $('#membership_requests #select_all').click((event) => {
        form.find('input:checkbox').each((i, el) => {
            $(el).prop('checked', true);
        });

        event.preventDefault();
    });

    $('#membership_requests #select_none').click((event) => {
        form.find('input:checkbox').each((i, el) => {
            $(el).prop('checked', false);
        });

        event.preventDefault();
    });
});
