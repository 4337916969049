$(document).ready(() => {
    const firstTab = $('.js-tab li').first();
    if (firstTab.length) {
        const tabName = firstTab.attr('class');

        firstTab.addClass('is-active');
        document.getElementById(tabName).classList.add('is-active');
    }

    $('.js-tab li a').click((event) => {
        event.preventDefault();
        const $tab = event.target.hash;
        $($tab).fadeIn('fast').siblings().hide();
        $(event.target).parent('li').addClass('is-active').siblings('li')
            .removeClass('is-active');
    });
});
