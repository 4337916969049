import Cookies from 'js-cookie';

document.addEventListener('cookie-consent-form-submit-successful', (e) => {
    // Accept button with id name 'cookie_consent_use_all_cookies'
    if (e.detail.id === 'cookie_consent_use_all_cookies') {
        Cookies.set('cookie_all', '1');
        Cookies.set('cookie_functional', '1');
        // eslint-disable-next-line
        dataLayer.push({ 'event': 'cookie_consent_all' });
    }

    // Refused button with id name 'cookie_consent_use_only_functional_cookies'
    if (e.detail.id === 'cookie_consent_use_only_functional_cookies') {
        Cookies.set('cookie_functional', '1');
        // eslint-disable-next-line
        dataLayer.push({ 'event': 'cookie_consent_functional' });
    }
}, false);
