/*
* This fixes ckeditor images and iframes to become responsive,
* by adding this classes to <p> tags within the editor.
* This will remove the width and height, replace them with other responsive classes.
* This file add classes to img and iframe tag within a <p> tag on vacancy detail page.
* */

$(document).ready(() => {
    // eslint-disable-next-line func-names
    $('.vacancy-detail p > img').each(function () {
        $(this).removeAttr('style').removeAttr('width').removeAttr('height');
        $(this).addClass('img-fluid py-4');
    });

    $('p').has('iframe').addClass('iframe-box');

    // eslint-disable-next-line func-names
    $('.vacancy-detail p > iframe').each(function () {
        $(this).removeAttr('style').removeAttr('width').removeAttr('height');
        $(this).addClass('responsive-iframe py-4');
    });
});
