/**
 * Carousel
 *
 * This component requires jQuery and Slick to function.
 * You can require it on the page using <div data-component="carousel"></div>
 */

require('slick-carousel');

$(() => {
    let $carousel = $('[data-component="carousel"]');
    if ($carousel) {
        $($carousel).slick({
            rows: 0,
            autoplay: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        });
    }
});
