import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl';

// for some reason not everything works correctly if we don't put this in
// the document ready callback
$(document).ready(() => {
    const datepickrs = document.getElementsByClassName('flatpickr');

    for (let i = 0; i < datepickrs.length; i += 1) {
        flatpickr(datepickrs[i], {
            dateFormat: 'Y-m-d H:i:S',
            inline: true,
            time_24hr: true,
            enableTime: datepickrs[i].dataset.enableTime !== 'false',
            locale: Dutch,
        });
    }

    const elements = document.querySelectorAll('.flatpickr-disabled');

    for (let i = 0; i < elements.length; i += 1) {
        const elem = elements[i];
        const currentValue = elem.value;
        const date = new Date(currentValue);
        const dates = JSON.parse(elem.dataset.values);
        const startDate = new Date(dates[0]);
        const endDate = dates[1] === null ? undefined : new Date(dates[1]);

        // Get an array of all dates between the startdate and enddate
        let currentDate = startDate;
        const pickerDates = [startDate];

        if (endDate) {
            while (currentDate.getTime() <= endDate.getTime()) {
                pickerDates.push(`${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)}-${currentDate.getDate()}`);
                // 1 day = 86.400.000 ms = 1000 * 60 * 60 * 24
                currentDate = new Date(currentDate.getTime() + 86400000);
            }
        }

        flatpickr(elem, {
            dateFormat: 'Y-m-d H:i:s',
            mode: 'multiple',
            defaultDate: pickerDates,
            inline: true,
            time_24hr: true,
            enableTime: true,
            locale: Dutch,
            minDate: new Date(date.getFullYear(), date.getMonth(), 1),
            maxDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            onChange(selectedDates, dateStr, instance) {
                instance.setDate(currentValue);
            },
        });
    }
});
