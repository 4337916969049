$(document).ready(() => {
    const accordionItems = $('footer .accordion-title');
    $.each(accordionItems, (index, item) => {
        $(item).click(() => {
            if ($(window).width() > 768) {
                return;
            }

            $(item).toggleClass('accordion-open');
            const element = item.nextElementSibling;
            if (element.style.maxHeight) {
                element.style.maxHeight = null;
            } else {
                // eslint-disable-next-line
                element.style.maxHeight = element.scrollHeight + 'px';
            }
        });
    });
});
