const Uppy = require('@uppy/core');
const XHRUpload = require('@uppy/xhr-upload');

function uploadConversationFile() {
    const route = $('#conversationFiles').data('route');
    const folderId = $('#conversationFiles').data('folder-id');

    const uploader = new Uppy({
        autoProceed: true,
    });

    uploader.setMeta({ folderId });
    uploader.use(XHRUpload, {
        endpoint: route,
    });

    $(document).on('change', '#conversationFiles', (event) => {
        const files = Array.from(event.target.files);

        files.forEach((file) => {
            uploader.addFile({
                name: file.name,
                type: file.type,
                data: file,
            });
        });
    });

    uploader.on('upload-success', (file, response) => {
        const $ids = $('.conversation__file-ids');
        $ids.val(`${$ids.val()}; ${response.body.id}`);

        $('.conversation__file-names').removeClass('d-none');
        $('.conversation__file-names+ul').append(`<li>${file.name}</li>`);
    });
}

$(document).ready(() => {
    $('.react-to-post').click(function reactToPost() {
        const reactionId = $(this).attr('data-id');
        const reactiontitle = $(this).attr('data-title');

        $('#reaction_reaction').val(reactionId);
        $('#reaction_title').html(reactiontitle);
        $('#react_to_reaction_banner').show();
    });

    $('#clear-reaction').click((event) => {
        event.preventDefault();
        $('#reaction_reaction').val();
        $('#react_to_reaction_banner').hide();
    });

    $('#conversationFilesButton').on('click', () => {
        document.getElementById('conversationFiles').click();
    });

    uploadConversationFile();
});
