$(document).ready(() => {
    $('.service__block h4').click((e) => {
        $(e.target).parentsUntil('ul', 'li').toggleClass('is-active');
    });

    $('.sidebar__block strong').click(function openWindow(e) {
        e.preventDefault();
        $(this).toggleClass('is-active');
        $(this).next('ul').fadeToggle('fast');
    });

    $('.overview__toggle').click((e) => {
        e.preventDefault();
        $('.search__sidebar ul').toggleClass('open');
    });
});
