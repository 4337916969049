// Load components
require('./components/carousel');

// FastClick polyfill to remove click delays on browsers with touch UIs
const fastClick = require('fastclick');

fastClick.attach(document.body);

// Main
require('./app/main');
