$(document).ready(() => {
    $('#profile_edit_avatar').change(function previewAvatar() {
        if (this.files && this.files[0]) {
            const reader = new FileReader();

            reader.onload = function changeAvatar(e) {
                $('#avatar-preview').attr('src', e.target.result);
            };

            reader.readAsDataURL(this.files[0]);
        }
    });
});
