/* eslint-disable */
$(document).ready(function () {
    if (!document.getElementById('cyber_security_company_address')) {
        return;
    }

    let autocomplete;
    const addressInput = document.getElementById('cyber_security_company_address');
    //only execute when form is available
    if (!addressInput) {
        return;
    }

    autocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */(document.getElementById('cyber_security_company_address')), {
            types: ['geocode'],
            componentRestrictions: {
                country: "BEL"
            },
        }
    );

    autocomplete.setFields(["formatted_address"]);

    autocomplete.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace();
        document.getElementById('cyber_security_company_address').value = place.formatted_address;
    });
});
