/* eslint-disable */
$(document).ready(() => {
    // When a project is permanent, it has no end date
    // -> hide the endDate datepicker when the 'permanent' checkbox is checked.

    const permanentCheckbox = document.getElementById('climate_project_management_permanent');
    const endDateArea = document.getElementById('climateProjectEndDateArea');

    if (permanentCheckbox && endDateArea) {
        // When it was checked on DOM load
        if (permanentCheckbox.checked) {
            endDateArea.classList.add('d-none');
        }

        // When the permanent changes
        permanentCheckbox.addEventListener('change', function () {
            if (this.checked) {
                endDateArea.classList.add('d-none');
            } else {
                endDateArea.classList.remove('d-none');
            }
        });
    }

    // When adding a financing and selecting 'other' as option for the 'financedBy' field,
    // the 'otherFinancer' field should be hidden.

    let financedByInputs = document.querySelectorAll('input[id^=climate_project_management_financing_][id*=_financedBy]');
    for (let i = 0; i < financedByInputs.length; i++) {
        if (financedByInputs[i].checked) {
            // Handle the selected item initially
            toggleOtherFinancerField(financedByInputs[i]);
        }

        // Add an event listener to handle every update
        financedByInputs[i].addEventListener('change', function (e) {
            toggleOtherFinancerField(e.currentTarget);
        });
    }

    // Add listeners for a newly created financing
    const addFinancingButton = document.getElementById('addFinancing');
    if (addFinancingButton) {
        addFinancingButton.addEventListener('click', function () {
            financedByInputs = document.querySelectorAll('input[id^=climate_project_management_financing_][id*=_financedBy]');
            if (financedByInputs[financedByInputs.length - 1].value === 'OTHER') {
                financedByInputs[financedByInputs.length - 1].checked = true;
            }

            toggleOtherFinancerField(financedByInputs[financedByInputs.length - 1]);

            for (let i = 0; i < financedByInputs.length; i++) {
                // Add an event listener to handle every update
                financedByInputs[i].addEventListener('change', function (e) {
                    toggleOtherFinancerField(e.currentTarget);
                });
            }
        });
    }

    // The initiator field should hide either the 'initiatorCitySelect' or the 'InitiatorOther' field
    const initiatorChoices = document.getElementsByName('climate_project_management[initiatorChoice]');
    const initiatorCity = document.getElementById('climate_project_management_initiatorCity');
    const initiatorIntermunicipal = document.getElementById('climate_project_management_initiatorIntermunicipal');
    const initiatorProvince = document.getElementById('climate_project_management_initiatorProvince');
    const initiatorOther = document.getElementById('climate_project_management_initiatorOther');

    if (initiatorChoices && initiatorChoices.length) {
        // Check the correct initiatorChoice checkbox
        let selectedInitiatorChoice = initiatorChoices[0];
        if (selectedInitiatorChoice !== null) {
            for (let i = 0; i < initiatorChoices.length; i++) {
                if (initiatorChoices[i].checked) {
                    selectedInitiatorChoice = initiatorChoices[i];
                }
            }
        }
        // Show the correct field (initiatorOther, initiatorCity, initiatorProvince or initiatorIntermunicipal)
        if (selectedInitiatorChoice && initiatorOther && initiatorCity && initiatorProvince && initiatorIntermunicipal) {
            toggleInitiatorFields(selectedInitiatorChoice, initiatorOther, initiatorCity, initiatorProvince, initiatorIntermunicipal);
            for (let i = 0; i < initiatorChoices.length; i++) {
                initiatorChoices[i].addEventListener('change', function (e) {
                    toggleInitiatorFields(e.currentTarget, initiatorOther, initiatorCity, initiatorProvince, initiatorIntermunicipal);
                });
            }
        }
    }

    let $carousel = $('.image-carousel');
    if ($carousel) {
        $($carousel).slick({
            rows: 0,
            autoplay: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        });
    }
});

function toggleOtherFinancerField(financedByInput) {
    const otherFinancerInput = financedByInput.parentElement.parentElement.parentElement.querySelector('input[id^=climate_project_management_financing_][id$=_otherFinancer]');
    if (financedByInput.value === 'OTHER') {
        otherFinancerInput.parentElement.classList.remove('d-none');
    } else {
        otherFinancerInput.parentElement.classList.add('d-none');
    }
}

function toggleInitiatorFields(initiatorChoice, initiatorOther, initiatorCity, initiatorProvince, initiatorMunicipal) {
    // Hide all initiator fields
    initiatorOther.parentElement.classList.add('d-none');
    initiatorCity.parentElement.classList.add('d-none');
    initiatorMunicipal.parentElement.classList.add('d-none');
    initiatorProvince.parentElement.classList.add('d-none');

    // Re-add the correct one
    if (initiatorChoice.value === 'OTHER') {
        initiatorOther.parentElement.classList.remove('d-none');
    } else if (initiatorChoice.value === 'INTERMUNICIPAL') {
        initiatorMunicipal.parentElement.classList.remove('d-none');
    } else if (initiatorChoice.value === 'PROVINCE') {
        initiatorProvince.parentElement.classList.remove('d-none');
    } else {
        initiatorCity.parentElement.classList.remove('d-none');
    }
}
