function disableForm(e) {
    const submit = $(this).find('input[type="submit"],button[type="submit"]');
    if (submit.prop('disabled')) {
        e.preventDefault();
        return;
    }

    submit.prop('disabled', 'disabled');
}

$(document).ready(() => {
    const form = $('.block-double-submit');
    if (form) {
        form.submit(disableForm);
    }
});
