/* eslint-disable */
$(document).ready(function () {
    // Remove the list item when clicking on the remove button
    $('.collection__list-item .btn-remove').click(function (e) {
        $(e.currentTarget.parentElement).remove();
    });

    $('.add-another-collection-widget').click(function () {
        const list = $($(this).attr('data-list-selector'));
        // Try to find the counter of the list or use the length of the list
        let counter = list.data('widget-counter') || list.children().length;

        // grab the prototype template
        let newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your field
        newWidget = newWidget.replace(/__name__/g, counter);

        // Increase the counter
        counter += 1;
        // And store it, the length cannot be used if deleting widgets is allowed
        list.data('widget-counter', counter);

        // Add the remove button
        const newRemoveButton = $('<span class="btn-remove" data-remove-counter="' + counter + '">Verwijder</span>');
        $(newRemoveButton).click(function (e) {
            $(e.currentTarget.parentElement).remove();
        });

        // create a new list element and add it to the list
        const newElem = $(list.attr('data-widget-tags')).html(newWidget);
        newRemoveButton.appendTo(newElem);
        newElem.appendTo(list);
    });
});
