/**
 * Main
 *
 * The main file, page logic which is needed on all pages will be included here.
 * Please note for specific components like a slider create a separated components js file
 * The components will be loaded through the component loader and are not needed on every page!
 */

require('jquery.mmenu');

$(() => {
    $('#nav').mmenu({
        scrollBugFix: true,
        offCanvas: {
            position: 'right',
        },
    }, {
        clone: true,
    });

    $('body')
        .on('click', 'a[href="#top"]', (event) => {
            event.preventDefault();

            $('html, body').animate({ scrollTop: 0 });
        });

    $('.header__user a').click(() => {
        $('.header__user ul').toggle();
    });

    $('input[type="checkbox"]').not('.search-checkbox').wrap('<div class="checkbox"></div>');

    $('<i></i>').insertAfter('.checkbox input[type="checkbox"]:not(.search-checkbox)');

    $('.search__filter').click((event) => {
        event.preventDefault();
        $(this).toggleClass('is-active');
        $('.search__sidebar ul').fadeToggle('fast');
    });

    $('.sidebar__block strong').click((event) => {
        event.preventDefault();
        $(this).toggleClass('is-active');
        $(this).next('ul').fadeToggle('fast');
    });

    $('.js-tab li a').click((event) => {
        event.preventDefault();
        const $tab = $(this).attr('href');
        $($tab).fadeIn('fast').siblings().hide();
        $(this).parent('li').addClass('is-active').siblings('li')
            .removeClass('is-active');
    });
});
